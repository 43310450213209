import './index.scss'
// import '@fortawesome/fontawesome-free/js/fontawesome'
// import '@fortawesome/fontawesome-free/js/solid'
// import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'
import 'bootstrap';
import Cookies from 'js-cookie';

$(function () {
    $('[data-toggle="popover"]').popover();

    $('#search-button').click(function () {
        window.location = "/sog?q=" + $('#search-text').val();
    });

    $('#search-text').on('keypress', function (e) {
        if (e.which === 13) {
            window.location = "/sog?q=" + $('#search-text').val();
        }
    });

    function initSmoothScrolling(container, animation) {
        /*
           * @param {String} container Class or ID of the animation container
           * @param {String} animation Name of the animation, e.g. smoothscroll
           */
        var sliderWidth = 0;
        var animationWidth = 0;
        var sliderHeight = $('>div>div:first-of-type', container).outerHeight(false);

        $('>div>div', container).each(function () {
            animationWidth += $(this).outerWidth(false);
        });

        // detect number of visible slides
        var slidesVisible = $(container).width() / $('>div>div:first-of-type', container).outerWidth(false);
        slidesVisible = Math.ceil(slidesVisible);

        // count slides to determine animation speed
        var slidesNumber = $('>div>div', container).length;
        var speed = slidesNumber * 2;

        // append the tail	
        $('>div>div', container).slice(0, slidesVisible).clone().appendTo($('>div', container));

        // Detect the slider width with appended tail
        $('>div>div', container).each(function () {
            sliderWidth += $(this).outerWidth(false);
        });

        // set slider dimensions
        $('>div', container).css({ 'width': sliderWidth, 'height': sliderHeight });

        // Insert styles to html
        $("<style type='text/css'>@keyframes " + animation + " { 0% { margin-left: 0px; } 100% { margin-left: -" + animationWidth + "px; } } .smooth-scroll  >div>div:first-of-type { -webkit-animation: " + animation + " " + speed + "s linear infinite; -moz-animation: " + animation + " " + speed + "s linear infinite; -ms-animation: " + animation + " " + speed + "s linear infinite; -o-animation: " + animation + " " + speed + "s linear infinite; animation: " + animation + " " + speed + "s linear infinite; }</style>").appendTo("head");

        // restart the animation (e.g. for safari & ie)	
        var cl = $(container).attr("class");
        $(container).removeClass(cl).animate({ 'nothing': null }, 1, function () {
            $(this).addClass(cl);
        });
    }

    function initCarouselWithNeighbors() {
        $('.carousel-item', '.show-neighbors').each(function () {
            var next = $(this).next();
            if (!next.length) {
                next = $(this).siblings(':first');
            }
            next.children(':first-child').clone().appendTo($(this));
        }).each(function () {
            var prev = $(this).prev();
            if (!prev.length) {
                prev = $(this).siblings(':last');
            }
            prev.children(':nth-last-child(2)').clone().prependTo($(this));
        });
    }

    $(document).ready(function () {
        // set up hover panels
        // although this can be done without JavaScript, we've attached these events
        // because it causes the hover to be triggered when the element is tapped on a touch device
        $('.hover').hover(function () {
            $(this).addClass('flip');
        }, function () {
            $(this).removeClass('flip');
        });

        initSmoothScrolling('.smooth-scroll', 'smoothscroll');

        initCarouselWithNeighbors();

    //    setTimeout(function () {
    //        $('#free-test').click(function () {
    //            console.log("Click trial");
    //            $("#free-trial")[0].click();
    //            console.log("Click done");
    //        });
    //    }, 2000);
    });

    //var settings = $mcSite.popup_form.settings;
    //function popupForm() {
    //    window.dojoRequire(["mojo/signup-forms/Loader"], function (L) { L.start( { "baseUrl": settings.base_url, "uuid": settings.uuid, "lid": settings.list_id, "uniqueMethods": true }) })
    //}

    //$('.mailchimpTrigger').click(function () {
    //    popupForm();
    //});

})